import React, { useRef } from "react";
import JobsSection from "./JobsSection";
import { StaticQuery, graphql } from "gatsby";
import HeroLayout1 from "components/common/hero/HeroLayout1";
import JobOpenings from "./JobOpenings";
import InfoSlider from "components/common/InfoSlider";
import TeamBenefits from "./TeamBenefits";
import FAQSection from "components/common/FAQSection";
import CandidateJourney from "./CandidateJourney";

export default function CareersPage() {
  const renderContent = data => {
    const jobsSectionRef = useRef(null);
    const pageData = {
      headerSection: {
        preTitle: "CAREERS",
        title: "Build a Safer Future with Us",
        description:
          "Here at Rhombus, everyone plays a critical role in achieving our mission to make the world safer with simple, smart, and powerful physical security solutions. No matter what team you’re on, the work you do here makes a positive impact across the globe.",
        image: data.headerImage,
      },
      infoSlides: [
        {
          tabTitle: "Deep Customer Focus",
          title: "We put our customers and partners first",
          description:
            "We're obsessed with improving our users' lives and constantly question how we can improve upon what already exists.",
          image: data.infoSlide1,
        },
        {
          tabTitle: "One Team",
          title: "We win as a team, We lose as a team",
          description:
            "Innovating in the physical security industry can't be accomplished by just a single person, which is why we collaborate with exceptional individuals who inspire us to be our best.",
          image: data.infoSlide2,
        },
        {
          tabTitle: "Think Greater",
          title: "Challenge the status quo",
          description:
            "We believe the best ideas can come from anywhere. We strive to create an open environment where individuals can contribute and make an impact.",
          image: data.infoSlide4,
        },
        {
          tabTitle: "Act with Integrity",
          title: "Trust and reputation mean everything to us",
          description:
            "We believe honest communication is key to success. We conduct business the way it should be - with high ethical standards and to always do what's right.",
          image: data.infoSlide3,
        },
      ],
      QA: [
        {
          question: "How can I check the status of my application?",
          answer:
            "We understand that you want to know your status as quickly as possible, so we do our best to provide updates within 1-2 weeks.",
        },
        {
          question: "What should I wear to my interview?",
          answer:
            "At Rhombus, there’s no dress code, but during the interview process we expect candidates to look presentable and professional.",
        },
        {
          question: "How do I prepare for a virtual interview?",
          answer:
            "Don’t stress! Interviewing with us is friendly and laid-back. We recommend setting up in a quiet space with a reliable internet connection.",
        },
        {
          question: "How can I request special accommodations?",
          answer:
            "If you need special assistance, please reach out to jobs@rhombus.com.",
        },
        {
          question: "What kind of entry-level roles are available at Rhombus?",
          answer:
            "We’re growing quickly and continually adding new roles across sales, marketing, operations, and more. View the job board down below to see open positions.",
        },
        {
          question:
            "Are there opportunities to become full-time after internships?",
          answer:
            "Definitely! If you’re a high performer and excel during your time with us, there’s potential to become a full-time Rhombus employee",
        },
        {
          question: "Where are Rhombus’ offices located?",
          answer: "We’re headquartered in vibrant Midtown Sacramento, CA!",
        },
      ],
    };

    return (
      <>
        <HeroLayout1 data={pageData.headerSection} />
        <JobOpenings jobsSectionRef={jobsSectionRef} />
        <InfoSlider data={pageData.infoSlides} title="Core Company Values" />
        <TeamBenefits />
        <CandidateJourney />
        <FAQSection
          QA={pageData.QA}
          color="var(--nuetral-100)"
          reverse
          image={data.faqImage}
          title="FAQs"
        />
        <JobsSection jobsSectionRef={jobsSectionRef} />
      </>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/careers/img/career-hero-1-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      infoSlide1: file(
        relativePath: { eq: "components/careers/img/value-1-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: { eq: "components/careers/img/value-2-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide3: file(
        relativePath: { eq: "components/careers/img/value-3-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide4: file(
        relativePath: { eq: "components/careers/img/value-4-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      faqImage: file(
        relativePath: { eq: "components/careers/img/faq-image-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
