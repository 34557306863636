import CareersPage from "components/careers/CareersPage";
import GlobalLayout from "components/page/GlobalLayout";
import React from "react";
import { Helmet } from "react-helmet";

export default function Jobs() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>Careers at Rhombus - Do Your Best Work</title>
        <meta
          name="description"
          content="Rhombus is hiring! Help us create a safer future. View our open jobs and have a career you love."
        />
      </Helmet>
      <CareersPage />
    </GlobalLayout>
  );
}
