import { css } from "linaria";
import React, { useEffect, useMemo, useState } from "react";
import JobFilterDropdown from "./JobFilterDropdown";
import {
  FancyTextLink,
  FancyTitleMed,
  GradientTextLink,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { useSetAtom } from "jotai";
import { jobListAtom } from "./atoms/careerState";
import Illustration from "components/integrations/marketplace/img/not-found.svg";

const titleContainer = css`
  display: flex;
  justify-content: center;
  text-align: center;
`;

const jobFiltersClass = css`
  text-align: left;
  display: flex;
  max-width: 1025px;
  width: 100%;
  gap: 6rem;
  justify-content: space-between;
  align-self: center;
  margin-top: 3rem;
  @media (max-width: 700px) {
    gap: 1rem;
  }
`;

const jobFilterClass = css`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 50%;
  flex-grow: 1;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const jobTeamsClass = css`
  width: 100%;
  max-width: 1025px;
  gap: 3.125rem;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5.75rem;
`;

const noListingsClass = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  max-width: 700px;
  align-self: center;
  p {
    font-size: 24px;
    color: var(--blue-900);
    @media (max-width: 600px) {
      font-size: 16px;
    }
    a {
      display: inline-block;
      font-size: inherit;
    }
  }
`;

const jobTeamClass = css`
  display: flex;
  flex-direction: column;
  gap: 1.375rem;
  width: 100%;
  text-align: left;
`;

const jobListingsClass = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2.1875rem;
`;

const jobListingRowClass = css`
  display: flex;
  align-items: center;
  a {
    font-size: 16px;
  }

  @media (max-width: 550px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const jobTitleClass = css`
  margin-right: auto;

  &:focus {
    text-decoration: underline;
  }
`;

const jobLocationClass = css`
  float: right;
`;

const initialFilter = { team: null, location: null };

export default function JobsSection({ jobsSectionRef }) {
  const [error, setError] = useState(null);
  const [rawListings, setRawListings] = useState([]);
  const [filter, setFilter] = useState(initialFilter);
  const setJobList = useSetAtom(jobListAtom);

  const locations = useMemo(() => {
    const listingsLocations = rawListings
      .map(
        listing => listing && listing.categories && listing.categories.location
      )
      .filter(x => x);

    // return only the unique values
    return [...new Set(listingsLocations)];
  }, [rawListings]);

  const teams = useMemo(() => {
    const listingsTeams = rawListings
      .map(listing => listing && listing.categories && listing.categories.team)
      .filter(x => x);
    // return only the unique values
    return [...new Set(listingsTeams)];
  }, [rawListings]);

  const displayTeams = useMemo(() => {
    const filteredTeams = filter.team
      ? teams.filter(team => filter.team === team)
      : teams;
    return filteredTeams
      .map(team => ({
        team,
        listings: rawListings.filter(({ categories }) => {
          if (!categories || !categories.team || categories.team !== team) {
            return false;
          }
          if (filter.location) {
            return filter.location === categories.location;
          }
          return true;
        }),
      }))
      .filter(({ listings }) => listings.length > 0);
  }, [teams, filter, rawListings]);

  const dropdownTeams = useMemo(() => {
    const nextTeams = teams;

    return [
      { label: "All Teams", value: null },
      ...nextTeams.map(teamName => ({ label: teamName, value: teamName })),
    ];
  }, [teams, filter, displayTeams]);

  const dropdownLocations = useMemo(() => {
    const nextLocations = locations;

    return [
      { label: "All Locations", value: null },
      ...nextLocations.map(locationName => ({
        label: locationName,
        value: locationName,
      })),
    ];
  }, [filter, locations, displayTeams]);

  // query the lever API
  useEffect(() => {
    fetch("https://api.lever.co/v0/postings/rhombus-systems?mode=json")
      .then(res => res.json())
      .then(data => {
        if (!data || !data.length) {
          setError("No job postings found.");
        }
        setRawListings(data.filter(x => x));
        setJobList(data);
        setFilter(initialFilter);
      })
      .catch(() => {
        setError(
          "Uh oh, there there was an error fetching our job postings. You can refresh and try again or visit https://jobs.lever.co/rhombus-systems/ to view our most recent job postings."
        );
      });
  }, []);

  const updateFilter = changes => {
    setFilter(prev => ({ ...prev, ...changes }));
  };
  return (
    <SectionContainer ref={jobsSectionRef}>
      <SectionInner style={{ flexDirection: "column" }}>
        <div className={titleContainer}>
          <FancyTitleMed>Do Your Best Work at Rhombus</FancyTitleMed>
        </div>
        <div className={jobFiltersClass}>
          <div className={jobFilterClass}>
            <TitleSmall>Teams</TitleSmall>
            <JobFilterDropdown
              options={dropdownTeams}
              onChange={nextTeam => updateFilter({ team: nextTeam })}
              value={filter.team}
            />
          </div>
          <div className={jobFilterClass}>
            <TitleSmall>Locations</TitleSmall>
            <JobFilterDropdown
              options={dropdownLocations}
              onChange={nextLocation =>
                updateFilter({ location: nextLocation })
              }
              value={filter.location}
            />
          </div>
        </div>
        <div className={jobTeamsClass}>
          {(displayTeams.length <= 0 || error) && (
            <div className={noListingsClass} key="no-listings">
              <img src={Illustration} alt="not found" />
              <p>
                We couldn&apos;t find any jobs matching your search. Try editing
                your search or{" "}
                <FancyTextLink href="https://jobs.lever.co/rhombus-systems/">
                  find more here
                </FancyTextLink>
              </p>
            </div>
          )}
          {!error &&
            displayTeams.map(({ team, listings }) => (
              <div className={jobTeamClass} key={team}>
                <TitleSmall>{team}</TitleSmall>
                <div className={jobListingsClass}>
                  {listings.map(({ text, hostedUrl, categories }) => (
                    <div className={jobListingRowClass} key={hostedUrl}>
                      <GradientTextLink
                        className={jobTitleClass}
                        href={hostedUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {text}
                      </GradientTextLink>
                      <span className={jobLocationClass}>
                        {categories.location ? categories.location : "N/A"}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </SectionInner>
    </SectionContainer>
  );
}
