import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitleMed,
  GradientTextLink,
  MainParagraph,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { css } from "linaria";
import React, { useEffect, useState } from "react";
import LocationIcon from "components/industries/img/icons/location.png";
import Arrow from "components/common/img/blue-arrow.png";
import { useAtomValue } from "jotai";
import { jobListAtom } from "./atoms/careerState";
import JobLoader from "components/loaders/JobLoader";

const jobListStyles = css`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
`;

const listing = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 10.5rem;
  flex-basis: calc((100% - 4rem) / 3);
  min-width: calc((100% - 4rem) / 3);
  padding: 2rem;
  border-radius: 0.8rem;
  border: 1px solid var(--nuetral-200);
  background: var(--white);
  transition: all 250ms ease;
  text-decoration: none;
  color: var(--body-text);
  :hover {
    border: 1px solid var(--blue-500);
  }
  @media (max-width: 1024px) {
    flex-basis: calc((100% - 2rem) / 2);
    min-width: calc((100% - 2rem) / 2);
  }
  @media (max-width: 600px) {
    flex-basis: 100%;
    min-width: 100%;
  }
`;

const listingHeader = css`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const iconRow = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  span {
    text-transform: capitalize;
  }
`;

const icon = css`
  height: 20px;
  aspect-ratio: 1;
  object-fit: contain;
  filter: brightness(0) grayscale(1);
`;

const JobOpenings = ({ jobsSectionRef }) => {
  const [featuredJobs, setFeaturedJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const scrollToJobs = () => {
    if (jobsSectionRef.current) {
      jobsSectionRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const jobList = useAtomValue(jobListAtom);

  useEffect(() => {
    if (!jobList) return;
    if (jobList.length >= 6) {
      setFeaturedJobs(jobList.splice(0, 6));
    } else {
      setFeaturedJobs(jobList);
    }
    setLoading(false);
  }, [jobList]);

  const handleJobRender = () => {
    if (loading || featuredJobs.length === 0) {
      return <JobLoader />;
    } else {
      return featuredJobs.map((item, index) => (
        <a
          className={listing}
          key={index}
          href={item.hostedUrl}
          target="_blank"
        >
          <div className={listingHeader}>
            <TitleSmall>{item.text}</TitleSmall>
            <div className={iconRow}>
              <img className={icon} alt="location" src={LocationIcon} />
              <span>{item.workplaceType}</span>
            </div>
          </div>
          <MainParagraph>{item.categories.team}</MainParagraph>
        </a>
      ));
    }
  };
  return (
    <SectionContainer style={{ background: "var(--nuetral-100)" }}>
      <SectionInner
        style={{ flexDirection: "column", alignItems: "center", gap: "4rem" }}
      >
        <FancyTitleMed style={{ textAlign: "center" }}>
          Featured Job Openings
        </FancyTitleMed>
        <div className={jobListStyles}>{handleJobRender()}</div>
        <GradientTextLink onClick={scrollToJobs}>
          See All Job Openings <img src={Arrow} />
        </GradientTextLink>
      </SectionInner>
    </SectionContainer>
  );
};

export default JobOpenings;
