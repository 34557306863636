import { StaticImage } from "gatsby-plugin-image";
import { css } from "linaria";
import React, { useEffect, useMemo, useRef, useState } from "react";

const jobFilterDropdownClass = css`
  position: relative;
  width: 100%;
  height: 3rem;
  font-size: 1rem;
`;

const jobFilterDropdownInnerClass = css`
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  cursor: pointer;
  background: linear-gradient(var(--white), var(--white)) padding-box,
    linear-gradient(to right, var(--blue-500), var(--teal-500)) border-box;
  border: 1px solid transparent;
  border-radius: 1.4rem;
  background-color: white;
  overflow: hidden;
`;

const openSelectButton = css`
  display: flex;
  text-align: left;
  padding: 8px 24px;
  border: none;
  height: 3rem;
  width: 100%;
  font-size: 18px;
  border-radius: 1.4rem;
  background-color: white;
  align-items: center;
  transition: all 500ms ease;

  svg {
    transition: 0.1s;
    margin-left: auto;
  }
  span {
    display: block;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
    padding-right: 1ch;
  }

  &:focus {
    border-radius: 0;
  }

  &:hover {
  }

  @media (max-width: 700px) {
    font-size: 14px;
  }
`;

const optionsClass = css`
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: 0;
  height: auto;
  transition: max-height 0.2s;
  overflow: auto;
  border-radius: 0 0 1.4rem 1.4rem;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export default function JobFilterDropdown({ options, value, onChange }) {
  const dropdownRef = useRef(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
    return () => {};
  }, [open]);

  const selectedOption = useMemo(
    () => options.find(option => option.value === value),
    [options, value]
  );

  const handleSelectOption = optionValue => {
    onChange(optionValue);
    setOpen(false);
  };

  return (
    <div className={jobFilterDropdownClass} ref={dropdownRef}>
      <div className={jobFilterDropdownInnerClass}>
        <button
          tabIndex={0}
          className={openSelectButton}
          onClick={() => setOpen(prev => !prev)}
        >
          <span>{selectedOption ? selectedOption.label : "Select"}</span>
          <svg
            style={{ ...(open && { transform: "rotate(180deg)" }) }}
            width="16.5px"
            height="9.8px"
            viewBox="0 0 16.5 9.8"
          >
            <path
              fill="none"
              stroke="#2A7DE1"
              strokeWidth="1"
              d="
              M 1, 1
              L 8.25, 7.8
              L 15.5, 1
            "
            />
          </svg>
        </button>
        <ul
          className={optionsClass}
          role="listbox"
          style={{ ...(open && { maxHeight: 300 }) }}
          tabIndex={open ? undefined : -1}
        >
          {options.map(({ label, value: optionValue }) => (
            <Option
              disabled={!open}
              key={optionValue}
              selected={value === optionValue}
              value={optionValue}
              onSelect={handleSelectOption}
            >
              {label}
            </Option>
          ))}
        </ul>
      </div>
    </div>
  );
}

const optionClass = css`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 8px 24px;
  height: 3rem;
  width: 100%;
  transition: all 500ms ease;

  span {
    display: block;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
    padding-right: 1ch;
  }

  img {
    width: 14px;
  }

  &:last-of-type {
    border-radius: 0 0 1.4rem 1.4rem;
  }

  &:hover {
    background-color: rgb(60, 135, 236, 0.2);
  }

  &:focus {
    background-color: rgb(60, 135, 236, 0.2);
  }
`;

function Option({ children: label, value, disabled, selected, onSelect }) {
  function handleSelect() {
    onSelect(value);
  }

  function handleKeyDown(event) {
    if (event.keyCode === 13) {
      handleSelect();
    }
  }

  return (
    <li
      className={optionClass}
      onClick={handleSelect}
      onKeyDown={handleKeyDown}
      role="option"
      aria-selected={selected}
      tabIndex={disabled ? -1 : 0}
    >
      <span>{label}</span>
      {selected && (
        <StaticImage
          src="../../images/icon-check.png"
          alt="selected icon"
          placeholder="blurred"
        />
      )}
    </li>
  );
}

Option.defaultProps = {
  children: null,
  value: null,
  selected: false,
  disabled: false,
  onSelect: () => {},
};
