import React, { useState } from "react";
import { styled } from "linaria/react";
import { css } from "linaria";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitleMed,
  MainParagraph,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";

import Icon1 from "components/common/icons/Union.png";
import Icon2 from "components/common/icons/phone-white.png";
import Icon3 from "components/common/icons/checks.png";
import Icon4 from "components/common/icons/Interview.png";
import Icon5 from "components/common/icons/DirectorInterview.png";
import Icon6 from "components/common/icons/Confetti.png";

import Arrow from "./img/arrow.svg";
import useIsMobile from "hooks/useIsMobile";

import leftIdleArrow from "components/integrations/img/left-idle.png";
import leftSelectedArrow from "components/integrations/img/left-selected.png";
import rightIdleArrow from "components/integrations/img/right-idle.png";
import rightSelectedArrow from "components/integrations/img/right-selected.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

const stepWrapper = css`
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 0.5rem;
  position: relative;
`;

const StepText = styled(TextContainer)`
  position: absolute;
  top: 100%;
  left: 0;
  width: 300px;
  margin-top: 4rem;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity 1s;
  @media (max-width: 768px) {
  }
`;

const stepContainer = css`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 0.5rem;
  height: 400px;
  ::-webkit-scrollbar {
    display: none;
  }
  scroll-behavior: smooth;
  .${stepWrapper}:last-of-type div {
    left: -220%;
    text-align: right;
  }
`;

const StepCircle = styled.div`
  width: 92px;
  height: 92px;
  background: ${props =>
    props.active ? "var(--gradient-base)" : "var(--nuetral-200)"};
  border-radius: 50%;
  transition: background 1s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

const iconStyles = css`
  height: 70%;
`;

const arrowStyles = css`
  @media (max-width: 1024px) {
    width: 50px;
  }
`;

const carouselWrapper = css`
  background-color: var(--white);
  width: 100%;
  height: 100%;
`;

const carouselSection = css`
  width: 100%;
  .swiper-button-prev,
  .swiper-button-next {
    color: transparent;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 250ms;
  }
  .swiper-button-prev {
    background-image: url(${leftIdleArrow});
    :hover {
      background-image: url(${leftSelectedArrow});
    }
  }
  .swiper-button-next {
    background-image: url(${rightIdleArrow});
    :hover {
      background-image: url(${rightSelectedArrow});
    }
  }
`;

const slideStyles = css`
  display: flex;
  justify-content: center;
`;

const slideInner = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  gap: 2rem;
  padding: 4rem;
  border-radius: 1rem;
  border: 1px solid var(--nuetral-200);
`;

const iconWrapper = css`
  width: 30px;
  height: 30px;
  background: var(--gradient-base);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;
export default function CandidateJourney() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { isMobile } = useIsMobile(820);

  const STEPS = [
    {
      title: "Candidate Consideration",
      description:
        "We’ll review your resume to make sure your experience aligns with what we’re looking for. We’ll then reach out to give you an update on your application.",
      icon: Icon1,
      iconAlt: "Peice of paper",
    },
    {
      title: "Recruiter Outreach",
      description:
        "If you’re a potential fit, we’ll call you to talk about your career interests and job details. From there, you’ll receive information on next steps.",
      icon: Icon2,
      iconAlt: "Phone",
    },
    {
      title: "Applicant Assessment",
      description:
        "Some roles may require an assessment to see your skills in action. Assessments will vary based on position.",
      icon: Icon3,
      iconAlt: "Checklist",
    },
    {
      title: "Manager and Team Interviews",
      description:
        "You’ll be meeting the hiring manager, followed by other team members, to understand your experience and the type of impact you can make at Rhombus.",
      icon: Icon4,
      iconAlt: "Interview",
    },
    {
      title: "Final Interviews",
      description:
        "The final round of interviews will be with our Directors and VPs. At this stage, you can learn about the company’s origin story and vision for the future.",
      icon: Icon5,
      iconAlt: "Final Interview",
    },
    {
      title: "Offer Package",
      description:
        "If we feel like you’ll be the perfect fit at Rhombus, we’ll email you an official offer package.",
      icon: Icon6,
      iconAlt: "Confetti",
    },
  ];

  const handleRender = () => {
    if (isMobile) {
      return (
        <div className={carouselSection}>
          <Swiper
            navigation
            pagination={{
              clickable: true,
            }}
            slidesPerView={1}
            loop={true}
            modules={[Navigation, Pagination]}
            className={carouselWrapper}
          >
            {STEPS.map((item, index) => (
              <SwiperSlide className={slideStyles}>
                <div className={slideInner}>
                  <div className={iconWrapper}>
                    <img
                      src={item.icon}
                      alt={item.iconAlt}
                      className={iconStyles}
                    />
                  </div>
                  <TextContainer>
                    <TitleSmall>{item.title}</TitleSmall>
                    <MainParagraph>{item.description}</MainParagraph>
                  </TextContainer>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      );
    } else {
      return (
        <div className={stepContainer}>
          {STEPS.map((step, index) => (
            <div className={stepWrapper}>
              <StepCircle
                onClick={() => setCurrentIndex(index)}
                onMouseEnter={() => setCurrentIndex(index)}
                active={index === currentIndex}
              >
                <img
                  src={step.icon}
                  alt={step.iconAlt}
                  className={iconStyles}
                />
              </StepCircle>
              {index === STEPS.length - 1 ? (
                <></>
              ) : (
                <img src={Arrow} alt="arrow" className={arrowStyles} />
              )}
              <StepText active={index === currentIndex}>
                <TitleSmall>{step.title}</TitleSmall>
                <MainParagraph>{step.description}</MainParagraph>
              </StepText>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <SectionContainer>
      <SectionInner style={{ flexDirection: "column", gap: "4rem" }}>
        <FancyTitleMed style={{ textAlign: "center" }}>
          Your Candidate Journey
        </FancyTitleMed>
        {handleRender()}
      </SectionInner>
    </SectionContainer>
  );
}
